import React from "react";
import locatorx from "../../assets/locatorx.png";
import request from "superagent";
import { Form, Col } from "react-bootstrap";
import NotificationModal from "../../components/NotificationModal";
import TermsandPrivacy from "../../components/TermsandPrivacy";

export default class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationModalShow: false,
      confirmationText: "",
      confirmationModalColor: "bg-success",
      password: "",
      confirmPassword: "",
      submitSuccess: false,
      
    };
  }

  componentDidMount() {
    const { token, appUserId } = this.props.match.params;

    localStorage.setItem("token", token);
    localStorage.setItem("appUserId", appUserId);
    this.setState({ token, appUserId });
  }

  handleConfirmationModal(
    confirmationText,
    onSubmitResult,
    confirmationModalColor = "bg-success"
  ) {
    this.setState({
      confirmationText,
      confirmationModalShow: true,
      confirmationModalColor
    });
    setTimeout(
      function() {
        this.setState({ confirmationModalShow: false });
        if (onSubmitResult === true) {
          prompt("logout");
        }
      }.bind(this),
      850
    );
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      const { newPassword, verifyPassword } = this.state;
      const { token } = this.props.match.params
      let body = {
        newPassword,
        verifyPassword
      };
      if (newPassword !== verifyPassword) {
        return alert("Error: Passwords do not match");
      } else
        request
          .post(
            `${this.props.apiUrl}appUsers/${token}/forgotPassword`
          )
          .send(body)
          .then(response => {
            if (response.body.success)
              this.setState({submitSuccess: true})
            else alert("Error: " + response.body.error);
          });
    }
    this.setState({ validated: true });
  }

  render() {
    const {
      confirmationModalShow,
      confirmationText,
      confirmationModalColor,
      submitSuccess,
      newPassword = '',
      verifyPassword = ''
    } = this.state;
    const requiredField = (
      <Form.Control.Feedback
        className="bg-primary invalid-feedback w-25 rounded text-white bg-danger px-1 font-weight-bold text-center position-relative speech-bubble mt-2"
        type="invalid"
      >
        Required Field
      </Form.Control.Feedback>
    );
    let modalClose = () =>
      this.setState({
        modalShow: false
      });

    return (
      <Form
        onSubmit={event => {
          this.handleSubmit(event);
        }}
        className="container py-3 d-flex flex-column justify-content-between h-100"
      >
        <NotificationModal
          confirmationModalShow={confirmationModalShow}
          modalClose={modalClose}
          confirmationText={confirmationText}
          confirmationModalColor={confirmationModalColor}
        />
        {submitSuccess ? (
          <div className="row h-100 justify-content-center">
            <div className="col-md-12 text-center">
              <div className="w-100 my-3 text-center">
                <img alt="company logo" src={locatorx} width="275" />
              </div>
              <div className="w-100 d-flex flex-column mt-5">
                <span className="my-2">
                  <h3>Password has been reset!</h3>
                </span>
                <span className="my-2">
                  <h3>Thank You!</h3>
                </span>
                <span className="my-2">
                  <h3>Retry Signing In!</h3>
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="row h-100 justify-content-center">
            <div className="col-xs-12 col-md-6 ">
              <div className="w-100 my-3 text-center">
                <img alt="company logo" src={locatorx} width="275" />
              </div>
              <Form.Group className="w-100 mt-5 mb-3">
                <Form.Label className="mt-2 mb-0 font-weight-bold">
                  Password
                </Form.Label>
                <Form.Control
                  required
                  name="newPassword"
                  className="form-control bg-white"
                  aria-label="Large"
                  field="newPassword"
                  value={newPassword}
                  type="password"
                  onChange={event => {
                    this.setState({ newPassword: event.target.value });
                  }}
                />
                {requiredField}
              </Form.Group>
              <Form.Group className="w-100 mb-5">
                <Form.Label className="mt-2 mb-0 font-weight-bold">
                  Confirm Password
                </Form.Label>
                <Form.Control
                  required
                  name="verifyPassword"
                  className="form-control bg-white"
                  aria-label="Large"
                  field="verifyPassword"
                  value={verifyPassword}
                  type="password"
                  onChange={event => {
                    this.setState({ verifyPassword: event.target.value });
                  }}
                />
                {requiredField}
              </Form.Group>
              <div className="w-100">
                <button type="submit" className="btn btn-primary btn-block">
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
        <TermsandPrivacy />
      </Form>
    );
  }
}
